<template>
    <modal ref="modalEditarDatos" titulo="Editar datos del registro" tamano="modal-lg" no-aceptar adicional="Actualizar" @adicional="updateRegistroTemporal">
        <div class="row mx-0">
            <div class="col-auto" />
            <div class="col">
                <div class="row mx-0">
                    <div class="col-12 mb-2">
                        <el-select
                        v-model="id_producto"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Buscar producto"
                        :remote-method="remoteMethod"
                        class="w-100"
                        @change="get_detalle"
                        >
                            <el-option
                            v-for="item in opciones"
                            :key="item.id"
                            :label="`${item.nombre} - ${item.presentacion}`"
                            :value="item.id"
                            />
                        </el-select>
                        <p v-show="producto_invalido" class="f-12 text-danger pl-2 mt-1">
                            <i class="icon-attention-alt text-general-red" />
                            Este producto no esta habilitado o no se vende en este CEDIS
                        </p>
                    </div>
                    <div class="col-6 mb-2">
                        <p class="text-general pl-3 f-14">Barcode / SKU</p>
                        <el-input v-model="barcode" disabled class="w-100" size="small" />
                    </div>
                    <div class="col-6 mb-2">
                        <p class="text-general pl-3 f-14">Valor Unitario</p>
                        <div class="d-middle">
                            <money v-model="valor" class="input-money" v-bind="money" />
                            <span class="border p-1 br-r-10 bg-gris f-600" style="margin-left: -8px; width: 50px; height:32px;">{{ calcularSiglaActual(id_cedis) }}</span>
                        </div>

                        <p v-show="valor_invalido" class="f-12 text-danger pl-2 mt-1">
                            <i class="icon-attention-alt text-general-red" />
                            {{ valor_invalido }}
                        </p>
                    </div>

                    <div class="col-6 mt-2">
                        <p class="text-general pl-3 f-14">Cantidad en inventario</p>
                        <el-input v-model="stock_actual" disabled class="w-100" size="small" />
                    </div>
                    <div class="col-6 mt-2">
                        <p class="text-general pl-3 f-14">Cantidad a comprar</p>
                        <el-input v-model="cantidad" class="w-100" size="small" />
                        <p v-show="cantidad_invalida" class="f-12 text-danger pl-2 mt-1">
                            <i class="icon-attention-alt text-general-red" />
                            {{ cantidad_invalida }}
                        </p>
                    </div>
                    <div class="col-6 mt-2">
                        <p class="text-general pl-3 f-14">Proveedor</p>
                        <el-select v-model="id_proveedor" size="small" clearable class="w-100">
                            <el-option
                            v-for="item in proveedores"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <p v-show="proveedor_invalido" class="f-12 text-danger pl-2 mt-1">
                            <i class="icon-attention-alt text-general-red" />
                            {{ proveedor_invalido }}
                        </p>
                    </div>
                    <div class="col-6 mt-2">
                        <p class="text-general pl-3 f-14">Fecha de Vencimiento</p>
                        <el-date-picker
                        v-model="vencimiento"
                        type="date"
                        size="small"
                        value-format="yyyy-MM-dd"
                        format="dd MMMM yyyy"
                        placeholder="Seleccionar"
                        />
                    </div>
                    <div class="col-12 mt-2">
                        <p class="text-general pl-3 f-14">Observación de la compra</p>
                        <el-input v-model="comentario" type="textarea" :rows="4" class="w-100" />
                    </div>
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
import {Money} from 'v-money'
import Productos from "~/services/productos/productos_stocks";
import CedisProductos from '~/services/cedis/cedis_productos'
export default {
    components:{
        Money,
    },
    data(){
        return{
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            id_temporal:null,
            id_producto:null,
            opciones:[],
            proveedores:[],

            barcode:null,
            valor:0,
            stock_actual:null,
            cantidad:null,
            id_proveedor:null,
            comentario:null,
            vencimiento:'',

            proveedor_invalido:null,
            cantidad_invalida:null,
            valor_invalido:null,
            producto_invalido:false,

            origenImportacion:1 // 1=> Barcode 2=> SKU


        }
    },
    computed:{
        id_cedis(){
            return Number(this.$route.params.id_cedis)
        },
        id_importacion(){
            return Number(this.$route.params.id_importacion)
        },
    },
    methods: {
        toggle(item, origen = 1){
            this.origenImportacion = origen
            this.id_temporal = item.id
            this.money.precision = this.getDecimales(this.id_cedis)
            this.initData(item)
            this.listaProveedores()
            this.$refs.modalEditarDatos.toggle();
        },
        async initData(item){
            this.opciones = []
            this.id_producto = item.id_producto
            this.barcode =  item.barcode
            this.stock_actual =  this.redondearNumero(item.stock_disponible)
            this.id_proveedor =  item.id_proveedor
            this.comentario =  item.comentario
            this.vencimiento =  item.vencimiento

            if(item.id_producto){
                this.opciones.push({
                    id:item.id_producto,
                    nombre:item.nombre_producto,
                    presentacion:item.presentacion
                })
            }

            if(item.id_proveedor === null){
                this.proveedor_invalido = item.proveedor
            }else{
                this.proveedor_invalido = null
            }
            if(isNaN(item.cantidad) || item.cantidad == 0){
                this.cantidad_invalida = item.cantidad
                this.cantidad =  null
            }else{
                this.cantidad_invalida = null
                this.cantidad =  item.cantidad
            }
            if(isNaN(item.valor) || item.valor == 0){
                this.valor_invalido = item.valor
                this.valor =  0
            }else{
                this.valor_invalido = null
                this.valor =  parseFloat(item.valor)
            }
            if(item.visible === null){
                this.producto_invalido = true
            }else{
                this.producto_invalido = false
            }
        },
        async updateRegistroTemporal(){
            try {

                if(this.id_producto === null){
                    this.notificacion('Advertencia','Debe seleccionar un Producto','warning')
                    return
                }

                if(isNaN(this.cantidad) || this.cantidad === null){
                    this.notificacion('Advertencia','La cantidad debe ser un numero valido','warning')
                    return
                }

                let proveedor = null
                if(this.id_proveedor != null && this.proveedor != ''){
                    let find = this.proveedores.find(o=>o.id == this.id_proveedor)
                    proveedor = find.nombre
                }

                let model = {
                    barcode: this.barcode,
                    id_producto: this.id_producto,
                    proveedor: proveedor,
                    id_proveedor: this.id_proveedor,
                    cantidad: this.cantidad,
                    valor: this.valor,
                    comentario: this.comentario,
                    id_importacion: this.id_importacion,
                    vencimiento:this.vencimiento
                }

                const {data} = await Productos.updateRegistroTemporal(this.id_temporal, model)
                this.notificacion('Exito','Registro actualizado','success')
                this.$emit('update')
                this.$refs.modalEditarDatos.toggle();

            } catch (e){
                this.error_catch(e)
            }
        },
        async remoteMethod(query){
            try {
                if(query.length > 2){
                    const busqueda = async() => {
                        let params = {
                            id_cedis: this.id_cedis,
                            query
                        }

                        const {data} = await Productos.buscadorProductos(params)
                        this.opciones = data.productos
                    }
                    await this.delay(busqueda)
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        async get_detalle(id_producto){
            try {

                let find = this.opciones.find(o=>o.id == id_producto)
                if(find.id_cp === null){
                    this.producto_invalido = true
                }else{
                    this.producto_invalido = false
                }
                this.barcode =  this.origenImportacion == 1 ? find.barcode : find.sku
                this.stock_actual =  this.redondearNumero(find.stock_disponible)

            } catch (e){
                this.error_catch(e)
            }
        },
        async listaProveedores(){
            try {
                const id_cedis = this.id_cedis

                const {data} = await CedisProductos.listaProveedores({id_cedis})
                this.proveedores = data.proveedores
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>
